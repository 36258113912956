.new-summary-sidebar{
    text-align: left;
    background-color: #f5f5f5;
    height: 100vh;
    // overflow-y: auto;
    padding: 0 20px;
    .section{
        &-1{
            font-size: 20px;
            font-weight: bold;
            padding: 40px 0 0 0;
        }
    }
    li{
        padding: 10px 0px;
        // margin: 20px 0px;
        cursor: pointer;
    }

    .subkeys:hover{
        transform: scaleX(1.1);
    }
    .subkeys-active{
        transform: scaleX(1.1);
  
    }

}