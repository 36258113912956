.summary-data {
    padding: 50px 100px 0 100px;
    display: flex;
  
    height: calc(100vh - 70px);
    width: 100%;
  
    &-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
  
      .title-search {
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .format-select{
          display: flex;
          gap: 20px;
          font-size: 16px;
          align-items: center;
          .select-box{
            min-width: 100px;
            .anticon{
              display: none;
            }
          }
        }
       
        .options{
          display: flex;
          gap: 20px;
          align-items: center;
          min-width: 400px;
          svg{
            font-size: 30px;
          }
          .save-btn {
            cursor: pointer;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
            // font-weight: normal;
          }
        }
      }
      .horizontal-line {
        width: inherit;
      }
      .summarydata-section {
        width: 100%;
        overflow-y: scroll;
        // height: 700px;
        text-align: left;
        ::-webkit-scrollbar {
          width: 5px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
          background: #DEDEDF;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #1849D6;
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
        .section {
          &-1,
          &-2,
          &-3,
          &-4,
          &-5 {
            display: flex;
            flex-direction: column;
            // gap: 20px;
            // max-width: 1000px;
            padding-top: 15px;
            // margin-left: 10px;
            // div:nth-of-type(3),
            // div:nth-of-type(4),
            // div:nth-of-type(5),
            // div:nth-of-type(6) {
            //   display: none;
            // }
            // cursor: pointer;
            img{
              width: 100%;
            }
          }
        }
        .rendersections {
          display: flex;
          justify-content: space-between;
          position: relative;
          width: 1200px;
          .horizontal-line {
            height: 2px;
            width: 1200px;

          }
          .prompt,
          .content {
            max-width: 85%;
            min-height: 35px;
          }
          .edit-section {
            display: flex;
            flex-direction: column;
            font-size: 20px;
            position: absolute;
            /* left: 500px; */
            right: 15px;
            gap: 5px;
            div {
              width: 40px;
              height: 40px;
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 20px;
              cursor: pointer;
              border-radius: 50px;
              box-shadow: 0 0 10px gray;
            }
            div:hover {
              transform: scale(
                1.2
              ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
            }
  
            img {
              height: 15px;
              width: 15px;
            }
          }
        }
      }
      .loader-section{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      
    }
    h1{
      font-size: 20px;
    }
    h2{
font-size: 18px;
    }
    .reload-icon{
      font-size: 18px;
    }
  }


  .edit-modal {
    width: 100% !important;
    .ant-modal-header{
      padding: 10px 20px;
    }
    .ant-modal-content{
 padding: 30px 0px;
    }
    .select-section{
      display: flex;
      gap: 20px;
      align-items: center;
      font-weight: bold;
      .select-box{
        min-width: 150px;
      }
      .compare{
        display: flex;
        align-items: center;
        gap: 10px;
        .icon{
          cursor: pointer;
        }
      }
    }
  
    .header-section{
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      .heading{
        font-size: 28px;
        font-weight: bold;
      }
     
    }
    .ant-modal-body{
      padding: 20px 100px;
    }
   
    .apply-buttons{
      display: flex;
      gap: 10px;
      justify-content: end;
    }
    .ant-modal-content {
      // height: 500px;
  
      .edit-modal-sections {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .TextField-root {
          width: 100%; /* Adjust width as needed */
        }
        
        .TextField-root textarea {
          min-height: 100px; /* Assuming you want a minimum height for the textarea */
          width: 100%; /* Ensures the textarea takes up full width of its container */
          overflow-wrap: break-word; /* Ensures long words or URLs break onto new lines */
        }
        
      }
    }
    .ant-modal-footer {
      display: none;
    }
    .prompt-modal-sections {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px 0;
    }
    .apply-buttons {
      text-align: right;
      padding-top: 10px;
    }
    .textarea-prompt{
      min-height: 200px;
      font-size: 18px;
    }
    .content-section{
      font-size: 18px;

      // max-height: 400px;

    }
    .compare-section{
      padding: 50px 50px;
      display: flex;
      justify-content: space-between;
      min-height: 400px;
      .select-section{
        padding-bottom: 10px;
      }
      .content{
        padding: 20px 10px;
        min-width: 400px;
        max-width: 700px;
        border: 2px solid black;
        background: white;
        height: 600px;
        overflow-y: scroll;
      }
    }
    .horizontal-line{
      margin-top: 20px;
    }
    .edit-option{
      width: 40px;
    height: 40px;
    background-color: white;
    display: flex;
    align-self: self-end;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    border-radius: 50px;
    box-shadow: 0 0 10px gray;
    position: absolute;
    right: 30px;
    img{
      width: 18px;

    }
    }
    .edit-option:hover{
      transform: scale(1.1);

    }
    .content-section-div{
      display: flex;
      flex-direction: column;
      padding-top: 20px;
  
    }
   .title{
    font-size: 18px;
   }
  }


  // compare modal css
  .compare-modal {
    width: 100% !important;
    .title{
      font-size: 18px;
     }
    .ant-modal-header{
      margin: 0;
      padding: 10px 20px 0px 20px;
    }
    .ant-modal-content{
 padding: 30px 0px;
    }
    .ant-modal-body{
      background: #f5f5f5;
    }
 
    .select-section{
      display: flex;
      gap: 20px;
      align-items: center;
      font-weight: bold;
      .select-box{
        min-width: 150px;
      }
      .compare{
        display: flex;
        align-items: center;
        gap: 10px;
        .icon{
          cursor: pointer;
        }
      }
    }
    .header-section{
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      .heading{
        font-size: 20px;
        font-weight: bold;
      }
     
    }
   
    .apply-buttons{
      display: flex;
      gap: 10px;
      justify-content: end;
    }
    .ant-modal-content {
      // height: 500px;
  
      .edit-modal-sections {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .TextField-root {
          width: 100%; /* Adjust width as needed */
        }
        
        .TextField-root textarea {
          min-height: 100px; /* Assuming you want a minimum height for the textarea */
          width: 100%; /* Ensures the textarea takes up full width of its container */
          overflow-wrap: break-word; /* Ensures long words or URLs break onto new lines */
        }
        
      }
    }
    .ant-modal-footer {
      display: none;
    }
    .prompt-modal-sections {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 20px 0;
    }
    .apply-buttons {
      text-align: right;
      padding-top: 10px;
    }
    .textarea-prompt{
      min-height: 200px;
      font-size: 18px;
    }
    .content-section{
      font-size: 18px;

      // max-height: 400px;

    }
    .compare-section{
      padding: 0px 120px;  
      display: flex;
      justify-content: space-between;
      min-height: 400px;
    
      .select-section{
        padding-bottom: 10px;
      }
      .content{
        padding: 20px 10px;
        min-width: 400px;
        max-width: 700px;
        border: 2px solid black;
        background: white;
        height: 600px;
        overflow-y: scroll;
      }
    }
    .horizontal-line{
      margin-top: 20px;
    }
   
  }
 

.clickable-link{
  width: fit-content;

  cursor: pointer;
  margin-bottom:20px ;
  font-size: 16px;

}
.clickable-link-active{
  width: fit-content;
  cursor: pointer;
  margin-bottom:20px ;
  font-size: 16px;
  color: #1849D6;
}