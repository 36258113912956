.search-folders {
  padding: 50px 200px 0 200px;
  display: flex;

  height: calc(100vh - 70px);
  width: 100%;

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;

    .title-search {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      .search-text{
        max-width: 800px;
        overflow-x: auto;
        padding-bottom: 10px;

      }
    }
    .menus {
      // align-items:;
      text-align: left;
      display: flex;
      flex-flow: column;
      gap: 20px;
      overflow-y: scroll;
      .upload-icon{
        cursor: pointer;
      }
     
      li {
        // background-color: #F5F5F7;
      }
      .module-title {
        font-size: 18px;
        font-weight: bold;
        margin: 10px 20px;
        background-color: #f5f5f7 !important;
        align-items: center;
        justify-content: space-between;
        input[type="file" i] {   
          opacity: 0;
         /* visibility: hidden; */
         position: absolute;
         /* left: 72%; */
         width:125px;
         right: 4%;
         display: flex;
         align-items: center;
         min-height: 40px;
         cursor: pointer;
        }
        .module-upload {
        i{
            display: none;
          }
          display: flex;
          justify-content: space-between;
          color: black;
          cursor: default;
          width: 100%;
          .module-header{
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .module-checkbox{
            padding-right: 10px;
            input{
              height: 10px;
            }
          }
         

        }
        ul {
          background: none !important;
          input[type="file" i] {   
            opacity: 0;
           /* visibility: hidden; */
           position: absolute;
           /* left: 72%; */
           width:40px;
           right: 4%;
           display: flex;
           align-items: center;
          min-height: 40px;
           cursor: pointer;
          }
        
        }
        .folder-rows,
        .file-rows {
          display: flex;
          align-items: center;
          gap: 10px;
          .folder-options,
          .file-options {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            // input[type="file"] {
            //   visibility: hidden;
            //   position: absolute;
            //   left: 90%;
            //   opacity: 0;
            //   cursor: pointer;
            // }
          }
        }
      }
      .modules {
        background-color: #f5f5f7;
        text-align: left;
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .module {
          &-title {
            font-size: 18px;
            font-weight: bold;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .upload {
              &-section {
                display: flex;
                align-items: center;
                width: 200px;
                justify-content: space-between;
              }
              &-icon {
                font-size: 22px;
                padding: 0 10px;
                cursor: pointer;
              }
              &-checkbox {
                .ant-checkbox-inner {
                  height: 30px;
                  width: 30px;
                  border-radius: 10px;
                }
                .ant-checkbox-checked .ant-checkbox-inner {
                  background-color: black;
                  border-color: black;
                }
                .ant-checkbox-inner:after {
                  width: 10px;
                  height: 15px;
                  border: 3px solid #fff;
                  border-top: 0;
                  border-inline-start: 0;
                }
              }
            }
          }
        }
      }
    }

    .btn-section {
      display: flex;
      align-self: end;
      gap: 20px;
      .generate-btn {
        background-color: black;
        color: white;
        height: 55px;
        width: 200px;
        border-radius: 50px;
        font-weight: bold;
        font-size: 16px;
      }
      .generate-btn:hover {
        background-color: black;
        color: white;
      }
    }
    .submenu-title {
      // margin: 20px;
      // pointer-events: none;
      // cursor: default;
      .folder-icon {
        height: 30px;
        // font-size: 30px;
        // color: yellow;
      }
      .ant-menu-title-content {
        // margin: 0 20px;
      }
     
    }
    .ant-menu-inline {
      background: none;
    }
  }
  .ant-menu-submenu-title {
    cursor: default !important;
    // padding: 10px !important;
    width: auto;
    margin: 0 20px;
  }
  .ant-menu-submenu-title:hover{
   background-color:  #E6EFFD !important;

  }
  .upload-icon,
  .view-icon {
    font-size: 20px !important;
    font-weight: bold;
    padding: 0px 10px;
  }
 
}

.scrollbar {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: red;
    display: none;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: transparent;
    margin-top: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: black;
    display: none;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: black;
      display: none;
    }
  }
}
// .modules-section {
//     width: 100%;
//     text-align: left;
//     display: flex;
//     flex-direction: column;
//     gap: 20px;
//     overflow: scroll;

//     .modules {
//       background-color: #f5f5f7;
//       text-align: left;

//       .module {
//         &-title {
//           font-size: 18px;
//           font-weight: bold;
//           margin: 10px 20px;
//           display: flex;
//           align-items: center;
//           justify-content: space-between;
//           .upload {
//             &-section {
//               display: flex;
//               align-items: center;
//               width: 200px;
//               justify-content: space-between;
//             }
//             &-icon {
//               font-size: 22px;
//               padding: 0 10px;
//               cursor: pointer;
//             }
//             &-checkbox {
//               .ant-checkbox-inner {
//                 height: 30px;
//                 width: 30px;
//                 border-radius: 10px;
//               }
//               .ant-checkbox-checked .ant-checkbox-inner {
//                 background-color: black;
//                 border-color: black;
//               }
//               .ant-checkbox-inner:after {
//                 width: 10px;
//                 height: 15px;
//                 border: 3px solid #fff;
//                 border-top: 0;
//                 border-inline-start: 0;
//               }
//             }
//           }
//         }
//       }
//       .horizontal-line {
//         height: 0.5px;
//         background-color: #e8e9ee;
//         margin: 10px 20px;
//       }
//       .module-folder {
//         display: flex;
//         padding: 10px 20px;
//         justify-content: space-between;
//         .file-name {
//           display: flex;
//           align-items: center;
//           gap: 10px;
//           width: 450px;
//           white-space: nowrap;
//           overflow: hidden;
//           text-overflow: ellipsis;
//         }
//         .file-icon {
//           height: 25px;
//           width: 25px;
//         }
//         .edit-upload-section {
//           display: none;
//         }
//       }
//       .module-folder:hover {
//         border-radius: var(--radi-md, 4px);

//         background: #dcdce9;
//         .edit-upload-section {
//           display: flex;
//           align-items: center;
//           width: 70px;
//           justify-content: space-between;
//           img {
//             cursor: pointer;
//           }
//         }
//       }
//     }
//   }

.pdf-modal {
  .ant-modal-content {
    height: 800px;
  }
}
input[type="checkbox"] {   
zoom: 1.5;
cursor: pointer;
}
.ant-modal-footer{
  display: none;
}
.rpv-default-layout__sidebar-headers{
  display: none;
}
.rpv-toolbar__right{
  display: none !important;
}
.fBdFME{
  height: 700px;
}