.my-workspace {
  padding: 50px 200px 0 200px;
  display: flex;

  height: calc(100vh - 70px);
  width: 100%;

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    // overflow-y: auto;

    
    .title-search {
      font-weight: bold;
    }
    .tabs-section {
      width: 100%;

      .ant-tabs-tab {
        font-size: 20px;
        color: black;
        text-shadow: 0 0 0.25px currentcolor;
        &-active {
          .ant-tabs-tab-btn {
            color: black;
          }
        }
      }
      .ant-tabs-top{
        max-height: 700px;
        overflow-y: auto;
      }
      .ant-tabs .ant-tabs-ink-bar {
        background-color: black;
        width: 100%;
      }
      .section {
        &-completed, &-draft {
          font-size: 16px;
          .folder {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            cursor: pointer;

            .file-name {
              display: flex;
              width: 450px;
              gap: 10px;
            }
          }
        }
      }
    }
  }
}
