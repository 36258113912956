.pdf-viewer{
    display: flex;
    width: 100%;
    height: 100%;

  width: 100%;
    .pdf-render{
        // height: calc(100vh - 300px);
        height: 700px;
        margin: auto;
        // padding: 100px;
        .rpv-core__inner-container {
            min-height: inherit;
    //    width: 1500px;
        }
        .rpv-default-layout__main {
            width: 800px;
        }
    }
}
.panel-section{
    overflow-y: auto;
    width: 90%;
    max-height: 700px;
}
.docx-wrapper{
background: white !important;
}