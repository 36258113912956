.sidebar {
  //   min-width: 250px;
  background-color: #f5f5f5;
  height: 100vh;

  .title-4 {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    padding: 20px 0;
    font-weight: 600;
    cursor: pointer;

    .gray-dot {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      background-color: #a2a3a2;
    }
    .ectd-logo{
      max-width: 300px;
    }
  }

  .container {
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 150px);
    justify-content: space-between;
    min-width: 250px;

    .folder-image {
      img {
        cursor: pointer;
        &:hover{
          transform: scale(1.1);
        }
      }
      
    }

    .user-details {
      display: flex;
      flex-direction: column;
      gap: 20px;
      font-weight: 500;
      
      .user {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .setting {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .details-icon {
        display: flex;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        // background-color: #efefef;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 400;
        cursor: pointer;
        svg{
          opacity: 0.6;
        }
      }
    }
    .section {
      align-self: flex-start;
    }
  }
  .container-summary {
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 150px);
    justify-content: space-between;
    min-width: 250px;
    overflow: scroll;

    .section-1 {
      font-weight: bold;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      //   padding: 10px 0;
      .header {
        text-align-last: left;
      }
    }

    // .section {
    //   &-2 {
    //     display: flex;
    //     flex-direction: column;
    //     align-items: start;
    //     text-align: justify;
    //     margin-left: 20px;
    //     font-size: 16px;
    //     font-weight: normal;
    //     padding: 10px 0;
    //     word-break: break-all;
    // }
    //   &-3 {
    //     line-height: 30px;
        
    //   }
    // }
    .section {
      &-1,
      &-2,
      &-3,
      &-4,
      &-5 {
        display: flex;
        flex-direction: column;
        align-items: start;
        text-align: justify;
        margin-left: 5px;
        font-size: 16px;
        font-weight: normal;
        padding: 10px 0;
        word-break: break-all;
        cursor: pointer;
      }
    }
    .section {
      &-1,
      &-2,
      &-3,
      header{
        font-weight: bold;
        cursor: pointer;
      }
      
    }
  }
}
.horizontal-line {
  height: 1px;
  background-color: #e8e9ee;
}
