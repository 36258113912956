

.App {
  text-align: center;
  display: flex;
  font-family: "Inter";
  // font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  .toaster {
  }
  .Toastify {
    .Toastify__toast-body {
      .Toastify__toast-icon.Toastify--animate-icon.Toastify__zoom-enter {
        display: none;
      }
      .Toastify__toast {
        &-container {
          right: 50%;
        }
      }
    }
    .Toastify__toast-container--top-right {
      left: 40%;
    }
    .Toastify__close-button.Toastify__close-button--light {
      display: none;
    }
  }

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.title {
  &-1 {
    font-size: 54px;
    font-weight: 300;
  }
 
  &-4 {
    font-size: 18px;
  }
  &-search{
    font-size: 28px;
    label{
      font-size: 18px;
      font-weight: normal;
    }
  }
}
.text{
  &-note{
    font-size: 18px;
    line-height: 28px; 
    color: #000000;
  }
}
.heading{
  &-2{
    font-size: 22px;
    font-weight: bold;

    margin-top: 20px;

  }
  &-3{
    font-size: 18px;
    font-weight: bold;
  }
}
.btn {
  &-back{
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #5252fa;
  gap: 10px;
  cursor: pointer;
  min-height: 50px;

  }
  &-solid-secondary{
    background-color: white;
    color: blue;
    border-radius: 20px;
    border: 1px solid;
    font-size: 20px;
    min-height: 50px;

    padding: 20px; 
   }

  &-solid-black{
    background-color: #000000;
    color: white;
    border-radius: 20px;
    font-size: 20px;
    min-height: 50px;

    padding: 20px; 
   }
   &-solid-blue{
    background-color: #1849D6 ;
    color: white ;
    border-radius: 20px;
    font-size: 20px;
    padding: 20px; 
    min-height: 50px;

   }
   &-solid-transparent{
    background-color: transparent;
        color: black;
    border-radius: 20px;
    font-size: 20px;
    padding: 20px; 
    min-height: 50px;
   }

}


// .scrollbar,
// body,
// div {
//     &::-webkit-scrollbar-track {
//         -webkit-box-shadow: inset 0 0 6px blue;
//         border-radius: 10px;
//         background-color: red;
//         display: none;
//         // margin-top: 10px;
//     }

//     &::-webkit-scrollbar {
//         width: 6px;
//         height: 6px;
//         background-color: transparent;
//         margin-top: 10px;
//     }

//     &::-webkit-scrollbar-thumb {
//         border-radius: 10px;
//         -webkit-box-shadow: inset 0 0 6px blue;
//         background-color: black;
//         display: none;
//     }

//     &:hover {
//         &::-webkit-scrollbar-thumb {
//             border-radius: 10px;
//             -webkit-box-shadow: inset 0 0 6px blue;
//             background-color: black;
//             display: none;
//         }
//     }
// }
::-webkit-scrollbar {
  width: 10px;
  max-height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #DEDEDF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1849D6;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.loader-section{
  width: 100%;
  &-vertical{
    height: 100%;
  }
  &-middle{
    height: 100%;
    /* width: 100%; */
    justify-content: center;
    align-items: center;  }
}








.active-submenu{
  background-color: blue;
}






