.home-page {
  padding: 50px 100px 30px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 150px);
  width: 100%;

  &-content {
    width: 900px;
    text-align: -webkit-center;

    .text-note {
      padding: 20px 0 100px 0;
      width: 700px;
      text-align: center;
    }

    .search-box {
     
      .ant-select-single {
        height: 60px;
        border: 1px solid #1f1f1f;
        border-radius: 50px;
        padding:12px 20px;
        // background-color: #f5f5f7;
        font-size: 20px;
        width: 900px;
       
        .anticon svg {
          display: inline-block;
          font-size: 25px;
          padding-right: 20px;
          color: #1f1f1f;
        }
        .ant-select-selector {
          background: none;
          border: none;
          box-shadow: none !important;
          // padding:0px 30px;

          input {
            font-size: 20px;
          }
        }
        .ant-select-selection-placeholder {
          font-size: 20px;
          text-align: left;
        }
      }

      .ant-select-focused {
        border: 1px solid #1f1f1f;
        box-shadow: none;
        background-color:#F7FAFF;

      }
      .ant-select-arrow {
        left: 2%;
      }
     
    }
  }
}

.ant-select-item.ant-select-item-option {
  font-size: 18px;
  .auto-complete-option{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
 
}
.add-new{
  display: flex;
  justify-content: space-between;
  .search-text{
    max-width: 600px;
    overflow-x: auto;
    padding-bottom: 10px;

  }
  .text-icon{
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .plus-icon{
    font-size: 20px;

  }
}
@media screen and (max-width: 1450px) {
    .home-page{
        padding: 20px 30px 10px 30px;

    }
  .search-box {
    .ant-select-single {
      width: 700px !important;
    }
  }
}

