.login-page{
    height: 100vh;
    width: 100%;
    .welcome-logo{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        gap: 20px;
        font-size: 42px;
        font-weight: bold;
        color: #1849D6;
        img{
            height: 60px;
        }

    }
    .login-box{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;    
        .content{
            display: flex;
    background-color: white;
    width: 480px;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    text-align: left;
    padding: 80px;
    border-radius: 10px;
    .ant-form-item{
        margin: 0px;
    }
    label{
        padding: 10px 0;
    }
    .welcome{

        &-text{
            font-size: 30px;
            font-weight: bold;
            display: flex;
            align-items: center;
            gap: 20px;
            img{
                height: 40px;
            }
        }
        &-quote{
            line-height: 22px;
        }
        &-signup{
            display: flex;
            padding-top: 20px;
            gap: 5px;
            justify-content: center;
            
            a{
                color: #1849D6;

            }
            span{
                cursor: pointer;
                color: #1849D6;
            }
        }
    }
    .input-box{
        border-radius: 50px;
        height: 40px;
        // background-color: #F7FAFF;
    }
        }
    }
    .custom-form{
        display: flex;
    flex-direction: column;
    gap: 1px;
    .submit-button{
        background-color: #1849D6;
        border-radius: 50px;
        font-size: 18px;
        height: 50px;
        margin-top: 10px;
    }
    }
    input:focus {
        background-color: #D4D7E3 !important;
      }
}